.rmsc {

    padding-top: 3px;
    padding-right: 0;
    padding-bottom: 3px;


    .gray{
        color: unset !important;
    }
    &.form-select {
        background-image: none !important;
    }

    .dropdown-container {
        background-color: transparent !important;
        border: none !important;
        box-shadow: none !important;

        &:focus {
            border: none !important;
            box-shadow: none !important;
        }

        .dropdown-heading {
            padding-left: 0;
            background: transparent;


        }
    }

}
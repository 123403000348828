//
// Pagination Custom
//

.pagination-footer{
  display: flex;
    align-items: baseline;
    justify-content: center;
}
.page-link{
&.pagination-button-next,&.pagination-button-prev{
  display: unset;
}
} 
.anticon {
    vertical-align: 0.1em;
}

.ant-steps {
    margin-bottom: 10px;
}

.ant-modal-wrap,
.ant-modal-mask {
    z-index: 10000;
}


.ant-upload-select-picture-card {
    border: 1px solid #d9d9d9 !important;

    &:hover {
        border-color: #1890ff !important;
    }
}

.antd-modal-lg-w-1000 {

    @media (min-width: 992px) {
        width: 1000px !important;
    }

}

.disable-now-button {
    .ant-picker-now-btn {
        display: none;
    }
}

.time-picker {

    .ant-picker-now {
        display: none;
    }
}

.layer-wrapper {
    position: relative;

    .layer {
        position: absolute;
        z-index: 1;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5019607843);
        transition: all 0.3s;
        content: " ";
        left: 0
    }

    .absolute-btn {
        position: absolute;
    }
}

.ant-tabs-wrapp {
    .ant-tabs-nav {
        // background-color: var(--kt-input-solid-bg);

        .ant-tabs-tab {
            margin-left: 10px;
        }
    }
}

.ant-solid {
    background-color: var(--kt-input-solid-bg);
    border-color: var(--kt-input-solid-bg);
    color: var(--kt-input-solid-color);
    transition: color 0.2s ease;
    border-radius: 0.475rem;
    padding: 0.775rem 1.25rem 0.775rem 1rem;
}

.w-out-mask {
    .ant-image-mask {
        &:hover {
            opacity: 0 !important;
        }
    }
}

.ant-popover {
    z-index: 1111;
}


.img-modal {
    .ant-modal-body {
        padding-top: 60px;
    }
}